<template>
  <router-view></router-view>
</template>

<style lang="scss">
@import "@/assets/scss/base";

#app {
  height: 100%;
}
</style>
